import React from 'react';
import "./NavbarMain.css";
import stopshop_logo from "../../Assets/stopshop_logo.png";
import { Link } from 'react-router-dom';

function NavbarMain() {
  return (
    <div className='sticky-top'>
      <div className='head_navbarmain'>
      <div className='row m-0 p-0'>
      <div className='col-xl-2 col-lg-3 col-md-2'>
      <div className="p-1 social-links">
      <a href="https://www.facebook.com/stopshoprei" target="_blank"><i class="fab fa-facebook-f"></i></a>
      <a href="https://twitter.com/360SynergyTech" target="_blank"><i class="fab fa-twitter"></i></a>
      <a href="https://www.instagram.com/stopshoprei/" target="_blank"><i class="fab fa-instagram"></i></a>
      <a href="https://www.linkedin.com/company/stopshoprei/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
  </div>
      </div>
      <div className='col-xl-10 col-lg-9 col-md-10 '>
      <div className='content_navbar'>
    <span className="pt-3 start"> Get quick appointment and technical support: +1 (959) 500-5594</span>

    <span className="pt-3 end">
    <span>160 Broadway, New York, NY 10038, USA</span> <span className='p-2'> info@stopshoprei.com </span>
    
    </span>
  </div>
      
      </div>

      </div>
      </div>
      <nav class="navbar navbar-expand-md navbar-dark">
    <div class="container">
    <Link to='/'>
        <a class="navbar-brand" href="#">
            <img src={stopshop_logo}/>
        </a>
</Link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse nav_itemmenu" id="collapsibleNavbar">
            <ul class="navbar-nav ml-auto nav_subitemmenu">
                <li class="nav-item">
                <Link to='/' className='contact_ushref'>
                    <a class="nav-link contact_ushref"  href="#">Home</a>
                    </Link>
                </li>
                
                <li class="nav-item">
                    <a class="nav-link" href="/#id_questions">OUR Service</a>
                    
                </li>
                <li class="nav-item">
                <Link to='/blog' className='contact_ushref'>
                    <a class="nav-link" href="#">Blog</a>
                    </Link>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">About Us</a>
                </li>
            </ul>
            <Link to='/contact-us' className='contact_ushref'>
            <button class="btn btn-login ml-md-2 contact_navbar">
            
          <a href='#contact_us' className='contact_ushref'>Contact us</a>  
         
            </button>
            </Link>
        </div>
    </div>
</nav>
    </div>
  )
}

export default NavbarMain
